/*
 * File: head.js
 * Project: our-wave-exhibits-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: April 10, 2024 at 10:38 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
const Sentry = require('@sentry/browser');


/**
 * Initialize
 */

if (process.env.ENV !== 'development') {
	Sentry.init({
		dsn: process.env.SENTRY_JS_DSN,
		release: `our-wave-exhibits-app@${process.env.PACKAGE_VERSION}`,
		environment: process.env.ENV,
	});
}
